// import { IsLoggedGuard } from './is-logged.guard';
import { NgModule } from '@angular/core';

import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AppComponent } from './app.component';
import { IsBackendUserGuard } from './is-backend-user.guard';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          () => import('../app/authentication/authentication.module').then((m) => m.AuthenticationModule)
      },
      {
        path: 'backend',
        canActivate: [IsBackendUserGuard],
        loadChildren: () => import('../app/backend/backend.module').then((m) => m.BackendModule)
      },
      {
        path: 'error',
        loadChildren: () => import('../app/errors/errors.module').then((m) => m.ErrorsModule)
      },
      {
        path: '',
        loadChildren:
          () => import('../app/authentication/authentication.module').then((m) => m.AuthenticationModule)
      },
      {
        path: '**',
        redirectTo: 'authentication'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
