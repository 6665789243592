import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private loggedInUserService: LoggedInUserService, private translate: TranslateService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = this.loggedInUserService.getLoggedInUser();
    const language = this.translate.currentLang;
    if (user && user.token && !request.url.includes("auth")) {
      request = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: user.token,
          language: language || 'en',
          'Access-Control-Allow-Origin': 'http://localhost:4201'
        })
      });

      return next.handle(request);
    }
    return next.handle(request);
  }
}
