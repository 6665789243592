import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CacheService {
  pathFile = '/assets/point.json';
  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache, must-revalidate',
      Pragma: 'no-cache'
    })
  };

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  getConfigFile(localFile: any) {
    const s$: Subscription = this.getConfigFileWithoutCache()
      .subscribe(res => {
        this.evaluateConfigVersion(localFile, res);
        setTimeout(() => {
          s$.unsubscribe();
        }, 0);
      }, () => {
        setTimeout(() => {
          s$.unsubscribe();
        }, 0);
      });
  }

  getConfigFileWithoutCache() {
    return this.httpClient.get<any>(this.pathFile, this.httpOptions);
  }

  evaluateConfigVersion(localConfig: any, serverConfig: any) {
    console.log('base', localConfig);
    console.log('srv', serverConfig);
    if (localConfig.v != serverConfig.v) {
      console.log('r');
      window.location.reload();
    }
    console.log('v => ', serverConfig.v);
  }
}
