import {ActivatedRouteSnapshot, RouterStateSnapshot, Params} from '@angular/router';
import {createFeatureSelector, ActionReducerMap, createSelector} from '@ngrx/store';

import * as fromRoute from '@ngrx/router-store';

import * as fromLoggedInUser from './logged-in-user.reducer';
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface AppState {
  routerReduce: fromRoute.RouterReducerState<RouterStateUrl>;
  loggedInUserReduce: fromLoggedInUser.LoggedInUserState;
}

export const reducers: ActionReducerMap<AppState> = {
  routerReduce: fromRoute.routerReducer,
  loggedInUserReduce: fromLoggedInUser.reducer
}

export const getRouterState = createFeatureSelector<fromRoute.RouterReducerState<RouterStateUrl>>('routerReduce');

export const getLoggedInUserState = createFeatureSelector<AppState>('appState');

export const getLoggedInUser = createSelector(
  getLoggedInUserState,
  (state: AppState) => state.loggedInUserReduce);

export const getUser = createSelector(getLoggedInUser, fromLoggedInUser.getLoggedInUser);

export const getRouterInfo = createSelector(
  getRouterState,
  state => {
    return state.state;
  }
);

@Injectable()
export class CustomSerializer implements fromRoute.RouterStateSerializer<RouterStateUrl> {

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {

    const {url} = routerState;
    const {queryParams} = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;

    while (state.firstChild) {
      state = state.firstChild;
    }

    const {params} = state;

    return {
      url,
      queryParams,
      params
    };
  }
}
