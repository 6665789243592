import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
  public active = false;


  constructor() { }

  public start() {
    this.active = true;
  }
  public stop() {
    this.active = false;
  }

  public isActive() {
    return this.active === true;
  }

}
