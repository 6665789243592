import * as _moment from 'moment';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber, isValidNumber, ParsedNumber, parseNumber } from 'libphonenumber-js';
import { ShowToastrService } from '../backend/show-toastr/show-toastr.service';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private showToastr: ShowToastrService,
    private translateService: TranslateService,) { }



  public static showValue(field, lang) {
    if (typeof field === 'string') {
      const data = JSON.parse(field);
      return data[lang] ? data[lang] : data['es'] ? data['es'] : data['en'] ? data['en'] : '';
    } else {
      return field[lang] ? field[lang] : field['es'] ? field['es'] : field['en'] ? field['en'] : '';
    }
  }


  static getDiffInYears(date1, date2) {
    let a = _moment(date1);
    let b = _moment(date2);
    return b.diff(a, 'years');
  }

  static getContrastColor(color) {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  static deleteProperty(obj, prop) {
    if (obj[prop]) {
      delete obj[prop];
    }
  }

  static getTimeFromMilitaryHour(data) {
    const dateStrings = data.split(':');
    if (dateStrings.length) {
      let a = ' AM';
      let hour = parseInt(dateStrings[0]);
      const minutes = dateStrings[1];
      if (hour > 12) {
        hour = hour - 12;
        a = ' PM';
        return hour.toString() + ':' + minutes + a;
      } else {
        return hour.toString() + ':' + minutes + a;
      }
      // return dateStrings[0];
    }
  }

  static getGroupSupplements(ContractSupplementPrices) {
    const supplements = [];
    ContractSupplementPrices.map(item => {
      if (supplements.length) {
        const element = supplements.find(e => {
          return item.Supplement.id === e.id;
        });
        if (!element) {
          supplements.push(item.Supplement);
        } else {
          if (!element.SupplementGroup) {
            element.SupplementGroup = [];
          }

          element.SupplementGroup.push(item);
        }
      } else {
        supplements.push(item.Supplement);
        supplements[0].SupplementGroup = [];
        supplements[0].SupplementGroup.push(item);
      }
    });
    return supplements;
  }

  static getCloneGroupSupplements(ContractSupplementPrices) {
    const supplements = [];
    if (ContractSupplementPrices) {
      ContractSupplementPrices.map(item => {
        if (supplements.length) {
          const element = supplements.find(e => {
            return item.Supplement.id === e.id;
          });
          if (!element) {
            supplements.push(item.Supplement);
          } else {
            if (!element.SupplementGroup) {
              element.SupplementGroup = [];
            }

            element.SupplementGroup.push({
              ...item.SupplementGroup,
              price: item.price
            });
          }
        } else {
          supplements.push(item.Supplement);
          supplements[0].SupplementGroup = [];
          supplements[0].SupplementGroup.push({
            ...item.SupplementGroup,
            price: item.price
          });
        }
      });
    }
    return supplements;
  }


  static addDays(date, day) {
    return new Date(Date.now() + day * 24 * 60 * 60 * 1000);
  }

  static getTimePretty(time) {
    let hours: number;
    let minutes: number;
    hours = parseInt((time / 3600) + '', 10);
    time = time - hours * 3600;
    minutes = parseInt((time / 60) + '', 10);
    time = time - minutes * 60;
    // return hours + ':' + minutes + ':' + time;
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  }

  static twelveHourTime(time) {
    time = UtilsService.getTimePretty(time);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  static humanizeDuration(time) {
    let hours: number;
    let minutes: number;
    hours = parseInt((time / 3600) + '', 10);
    time = time - hours * 3600;
    minutes = parseInt((time / 60) + '', 10);
    time = time - minutes * 60;
    // return hours + ':' + minutes + ':' + time;
    return (hours < 10 ? '0' + hours : hours) + 'h : ' + (minutes < 10 ? '0' + minutes : minutes) + 'm';
  }

  static getOnlyDate(date: string): string {
    const dateStrings = date.split('T');
    if (dateStrings.length === 2) {
      return dateStrings[0];
    }
  }

  /**
   *
   * @param date YYYY-MM-DD
   */
  static getDateFromOnlyDateString(date) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1);
    d.setDate(elem[2]);

    return d;
  }

  static getDateAndTime(date, hour?) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(elem[0], elem[1] - 1);
    d.setDate(elem[2]);

    if (hour) {
      const elemHour = hour.split(/[/ :]/);
      d.setHours(elemHour[0], elemHour[1], 0);
    }

    return d;
  }

  static getDiffInHoursMinutes(timeStart, timeEnd) {
    const hourDiff = timeEnd - timeStart;
    const diffHrs = Math.floor((hourDiff % 86400000) / 3600000);
    let diffMins = Math.round(((hourDiff % 86400000) % 3600000) / 60000);
    diffMins = diffMins + (diffHrs * 60);
    return {
      diffHrs,
      diffMins: diffMins % 60
    };
  }

  // date example YYYY-MM-DD
  static buildDateFromApiString(date) {
    const elem = date.split('-');
    const d = new Date();
    d.setFullYear(parseInt(elem[0]));
    d.setMonth(parseInt(elem[1]) - 1);
    d.setDate(parseInt(elem[2]));
    return d;
  }

  static addHours(time, seconds, BusStops, SelectedBusStops) {
    const elem = time.split(/[/ :]/);
    const d = new Date();
    d.setHours(elem[0], elem[1], 0);
    // add seconds
    d.setSeconds(d.getSeconds() + seconds);

    BusStops.forEach(bus => {
      SelectedBusStops.forEach(bus2 => {
        if (bus.id === bus2) {
          d.setSeconds(d.getSeconds() + bus.duration);
        }
      });
    });

    return ((d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()));
  }


  static isObjectEquals(x, y): boolean {
    if (x === y) {
      return true;
    }

    if (!(x instanceof Object) || !(y instanceof Object)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }


    for (let p in x) {

      for (const p in x) {

        if (!x.hasOwnProperty(p)) {
          continue;
        }

        if (!y.hasOwnProperty(p)) {
          return false;
        }

        if (x[p] === y[p]) {
          continue;
        }

        if (typeof x[p] !== 'object') {
          return false;
        }

        if (!this.isObjectEquals(x[p], y[p])) {
          return false;
        }
      }

      for (let p in y) {

        for (const p in y) {

          if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
            return false;
          }
        }
        return true;
      }
    }
  }


  // static resolvePropertyByPath(obj: any, path: string[]) {
  //   let result = path.reduce((prev, curr) => {
  //     return prev ? prev[curr] : null;
  //   }, obj || self);

  //   return result;
  // }

  static parseDuration(time) {
    const elem = time.split(/[/ :]/);
    const d = new Date();
    d.setHours(elem[0], elem[1], 0);

    return elem[0] + ':' + elem[1];
  }

  static getTimeInSeconds(timeString) {
    timeString = timeString.split(':');
    if (timeString.length > 3) {
      throw new Error('Hay que implementar esto');
    }
    let index = 0;
    let totalTime = 0;
    for (let i = timeString.length - 1; i >= 0; i--) {
      totalTime += +(timeString[i]) * Math.pow(60, index + 1);
      index++;
    }
    return totalTime;
  }

  // static getTimeInSeconds(time) {
  //   time = time.split(":");
  //   return (parseInt(time[0]) * 3600) + (parseInt(time[1]) * 60) + parseInt(time[2])
  // }

  static getSecondsInTime(seconds) {
    const hours = seconds / 3600; // una hora tiene 3600 segundos
    seconds = seconds % 3600;
    const minutes = seconds / 60;
    const minutesS = ('000' + minutes).slice(-2);

    seconds = seconds % 60;
    const secondsS = ('000' + seconds).slice(-2);
    return hours + ':' + minutesS + ':' + secondsS;
  }


  static resolvePropertyByPath(obj: any, path: string[]) {
    const result = path.reduce((prev, curr) => {

      return prev ? prev[curr] : null;
    }, obj || self);

    return result;
  }



  static getMapFromArray(array: any[], key?) {
    let map;
    if (key) {
      map = array.reduce((acc, item) => acc.set(item[key], item), new Map());
    } else {
      map = array.reduce((acc, item) => acc.set(item.id, item), new Map());
    }
    return map;
  }

  static S4(): string {
    // tslint:disable-next-line:no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  static generator(): string {
    return `${UtilsService.S4()}${UtilsService.S4()}-${UtilsService.S4()}-${UtilsService.S4()}-${UtilsService.S4()}-${UtilsService.S4()}${UtilsService.S4()}${UtilsService.S4()}`;
  }


  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }


  getDayNumber(date: string) {
    switch (new Date(date).getDay()) {
      case 0:
        return 'Domingo';
        break;
      case 1:
        return 'Lunes';
        break;
      case 2:
        return 'Martes';
        break;
      case 3:
        return 'Miércoles';
        break;
      case 4:
        return 'Jueves';
        break;
      case 5:
        return 'Viernes';
        break;
      case 6:
        return 'Sabado';
        break;
      default:
        return '-';
        break;
    }
  }

  jsUcfirst(str) {

    // let ayuda =

    const ayuda =

      'de la que el en y a los del se las por un para con no una su al lo como más pero sus le ya o este sí porque esta entre cuando muy sin sobre también me hasta hay donde quien desde todo nos durante todos uno les ni contra otros ese eso ante ellos e esto mí antes algunos qué unos yo otro otras otra él tanto esa estos mucho quienes nada muchos cual poco ella estar estas algunas algo nosotros mi mis tú te ti tu tus ellas nosotras vosostros vosostras os mío mía míos mías tuyo tuya tuyos tuyas suyo suya suyos suyas nuestro nuestra nuestros nuestras vuestro vuestra vuestros vuestras esos esas estoy estás está estamos estáis están esté estés estemos estéis estén estaré';

    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        if (ayuda.indexOf(word.toLowerCase()) !== -1) {
          return word;
        } else {
          return word.replace(word[0], word[0].toUpperCase());
        }
      })
      .join(' ');
  }

  formatDate(date) {
    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12'
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return day + '/' + monthNames[monthIndex] + '/' + year;
  }

  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {

      // var result =
      //   a[property].toString().toLowerCase() <
      //     b[property].toString().toLowerCase()
      //     ? -1
      //     : a[property].toString().toLowerCase() >
      //       b[property].toString().toLowerCase()
      //       ? 1
      //       : 0;

      const result =
        a[property].toString().toLowerCase() <
          b[property].toString().toLowerCase()
          ? -1
          : a[property].toString().toLowerCase() >
            b[property].toString().toLowerCase()
            ? 1
            : 0;

      return result * sortOrder;
    };
  }

  isAdmin(roles: any[]) {
    if (roles && roles.length) {
      let result = false;
      roles.forEach((item) => {
        if (item.type === 'admin' || item.type === 'Admin') {
          result = true;
        }
      });
      return result;
    } else {
      return false;
    }
  }

  // errorHandle(error, nomenclator?, action?) {
  //   const alternative = nomenclator
  //     ? action
  //       ? this.translateService.instant('Error ') + action + ' ' + nomenclator
  //       : this.translateService.instant('Error ') + action
  //     : this.translateService.instant(
  //       `Server response failed, check your connection to the network, or contact the administrators`,
  //     );
  //   let msg = alternative;
  //   if (error.errors && error.errors.length) {
  //     msg = error.errors.map((item) => item.title + ' ' || item.message + ' ');
  //   } else if (error.error.errors) {
  //     msg = error.error.errors.map((item) => item.title + ' ' || item.message + ' ');
  //   } else if (error.error && error.error.length) {
  //     msg = error.error.map((item) => item.title + ' ' || item.message + ' ');
  //   } else {
  //     msg = error.error.message;
  //   }
  //   this.showToastr.showError(msg, 'Error', 9000);
  // }

  errorHandle(error, nomenclator?, action?) {
    let alternative = nomenclator
                      ? action
                        ? 'Error ' + action + ' ' + nomenclator
                        : 'Error ' + action
                      : 'La respuesta del servidor ha fallado, chequee su conexión de red o póngase en contacto con un administrador de sistema.';
    let msg         = alternative;
    if (error.errors && error.errors.length) {
      error.errors.forEach(e => {
        this.showToastr.showError(e.title || e.message, e.field);
      });
      return;
    }
    else if (error.error && error.error.errors) {
      error.error.errors.forEach(e => {
        this.showToastr.showError(e.title || e.message, e.field);
      });
      return;
    }
    else if (error.error && error.error.length) {
      error.error.forEach(e => {
        this.showToastr.showError(e.title || e.message, e.field);
      });
      return;
    }
    else if (error.error) {
      msg = error.error.message;
      if (!msg) {
        msg = 'La respuesta del servidor ha fallado, chequee su conexión de red o póngase en contacto con un administrador de sistema.';
      }
      this.showToastr.showError(msg, 'Error');
    }
  }

  public clone(): any {
    const cloneObj = new (<any>this.constructor())();
    for (const attribut in this) {
      if (typeof this[attribut] === 'object') {
        cloneObj[attribut] = this.clone();
      } else {
        cloneObj[attribut] = this[attribut];
      }
    }
    return cloneObj;
  }

  validateEmail(email) {
    const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    return pattern.test(email);
  }

  roundPrices(price) {
    return Math.round(price * 100) / 100;
  }

  validatePhone(cellphone) {
    return !(cellphone.length !== 8 ||
      cellphone[0] !== '5' ||
      isNaN(Number(cellphone)));
  }



  // formatPhone(cellphone): String {
  //   if (cellphone && this.validatePhone(cellphone)) {
  //     return formatNumber(parseNumber(cellphone, 'CU'), 'National');
  //   } else if (cellphone && isValidNumber(cellphone, 'US')) {
  //     return formatNumber(parseNumber(cellphone, 'US'), 'National');
  //   } else {
  //     return cellphone;
  //   }
  // }

  formatPhone(cellphone): String {
    if (cellphone && this.validatePhone(cellphone)) {
      return formatNumber(<ParsedNumber>parseNumber(cellphone, 'CU'), 'National');
    } else if (cellphone && isValidNumber(cellphone, 'US')) {
      return formatNumber(<ParsedNumber>parseNumber(cellphone, 'US'), 'National');
    } else {
      return cellphone;
    }
  }


  public formatAddress(address: String): String {
    return address.split('*_*').join(' ');
  }

  getDirtyValues(form: any) {
    const dirtyValues = {};

    Object.keys(form.controls).forEach(key => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }


  parserLanguage(item, language) {
    if (item[language] && item[language].length) {
      return item[language];
    } else if (item['en'] && item['en'].length) {
      return item['en'];
    } else {
      return item['es'];
    }
  }

  hasPermissions(array, text): boolean {
    const valueIndex = array.findIndex(item => {
      return item === text;
    });

    return valueIndex >= 0;
  }

  generateUuid(): string {
    let tempId = '';

    tempId = UtilsService.generator();

    return tempId;
  }

  keyPressAlpha(event) {

    const inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphaSpace(event) {

    const inp = String.fromCharCode(event.keyCode);

    if ((/[a-zA-Z]/.test(inp)) || (inp === ' ')) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
