import { CookieService } from "ngx-cookie-service";
// import { EncryptDecryptService } from './core/encrypt-decrypt/encrypt-decrypt.service';
import { ProgressBarService } from "./shared/progress-bar/progress-bar.service";
import { Component } from "@angular/core";
import { SplashScreenService } from "./core/splash-screen/splash-screen.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { LoadingSpinnerService } from "./core/loading-spinner/loading-spinner.service";
import { Subject } from "rxjs";
import { LoggedInUserService } from "./core/loggedInUser/logged-in-user.service";
import { SocketIoClientService } from "./core/socket-io-client/socket-io-client.service";
import { CacheService } from "./core/cache/cache.service";
import config from "../assets/point.json";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "agency-hotels";
  _unsubscribeAll = new Subject<any>();

  constructor(
    private translate: TranslateService,
    public loadingSpinner: LoadingSpinnerService,
    private _splashScreenService: SplashScreenService,
    public loggedInUserService: LoggedInUserService,
    private cookieService: CookieService,
    // private encryptDecryptService: EncryptDecryptService,
    public socketioClient: SocketIoClientService,
    public progressBarService: ProgressBarService,
    private cacheService: CacheService
  ) {
    this.cacheService.getConfigFile(config);

    const paymentFrom = this.cookieService.check("payment-from");
    if (paymentFrom) {
      this.cookieService.delete("payment-from");
      this.cookieService.delete(
        "payment-from",
        "*",
        "." + environment.mainDomain
      );
      this.cookieService.delete(
        "payment-from",
        "/",
        "." + environment.mainDomain
      );
      this.cookieService.delete(
        "payment-from",
        "/backend",
        "." + environment.mainDomain
      );
      document.cookie =
        "payment-from=;domain=." + environment.mainDomain + ";path=/;max-age=0";
    }

    let language = environment.defaultLanguage;
    if ("language" in localStorage) {
      language = localStorage.getItem("language");
      language = language === "es" || language === "en" ? language : "es";
      this.translate.setDefaultLang(language);
      this.translate.use(language);
      localStorage.setItem("language", language);
    } else {
      this.translate.setDefaultLang(language);
      localStorage.setItem("language", language);
    }

    this.loadingSpinner.start();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
