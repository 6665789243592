import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
}                     from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError }            from 'rxjs/operators';
import { Router }                from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { ToastrService }         from 'ngx-toastr';
import { LoggedInUserService }   from '../loggedInUser/logged-in-user.service';
import { UtilsService }          from '../utils/utils.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private loggedInUserService: LoggedInUserService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private utilsService: UtilsService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.loggedInUserService.logout();
        this.authenticationService.logout();
        this.toastr.error('Usuario no autorizado.', 'Error!', {
          timeOut      : 5000,
          progressBar  : true,
          positionClass: 'toast-bottom-right'
        });
        this.router.navigate(['']).then(() => true);
      }
      if (err.status === 403) {
        // auto logout if 401 response returned from api
        this.toastr.error('Usuario no autorizado.', 'Error!', {
          timeOut      : 5000,
          progressBar  : true,
          positionClass: 'toast-bottom-right'
        });
      }
      if (err.status === 404) {
        if (err.error.errors[0].title == 'No Mapi found') {
          this.router.navigate(['/backend/flight']).then(() => true);
          this.toastr.error('Mapi no encontrado', 'Error.', {
            timeOut      : 5000,
            progressBar  : true,
            positionClass: 'toast-bottom-right'
          });
        } else {

          this.toastr.error('Error 404.', 'Error!', {
            timeOut      : 5000,
            progressBar  : true,
            positionClass: 'toast-bottom-right'
          });
          this.router.navigate(['error/404']).then(() => true);
        }
      } else if (err.status === 400 || err.status === 500) {
        this.utilsService.errorHandle(err);
      } else if (err.status === 0) {
        this.toastr.error('El servidor esta caído, o revise su conexión.', 'Error!', {
          timeOut      : 5000,
          progressBar  : true,
          positionClass: 'toast-bottom-right'
        });
      }

      const error = err.error ? err.error.message : err.statusText;
      return next.handle(request);
    }));
  }
}
