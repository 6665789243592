import { Action } from '@ngrx/store';

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const CLEAR_LOGGED_IN_USER = 'CLEAR_LOGGED_IN_USER';
export const MY_RESERVATION_CHANGE = 'MY_RESERVATION_CHANGE';

export class SetLoggedInUser implements Action {
  readonly type = SET_LOGGED_IN_USER;

  constructor(public payload: any) {
  }
}

export class MyReservationChange implements Action {
  readonly type = MY_RESERVATION_CHANGE;

  constructor(public payload: any) {
  }
}

export class ClearLoggedInUser implements Action {
  readonly type = CLEAR_LOGGED_IN_USER;
}

export type LoggedInUserActions = SetLoggedInUser | ClearLoggedInUser | MyReservationChange;
