import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { LoggedInUserService } from 'src/app/core/loggedInUser/logged-in-user.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketIoClientService implements OnDestroy {
  public url = environment.apiUrl + 'connect';
  private socket: SocketIOClient.Socket;
  private socketSearch: SocketIOClient.Socket;
  loggedInUser: any;
  _unsubscribeAll: Subject<any>;

  constructor(private loggedInUserService: LoggedInUserService) {
    this._unsubscribeAll = new Subject<any>();

    this.loggedInUser = this.loggedInUserService.getLoggedInUser();

    if (this.loggedInUser) {
      // this._initSocket(this.loggedInUserService.getTokenInUser());
    }

    this.loggedInUserService.loggedInUserUpdated$.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
      this.loggedInUser = this.loggedInUserService.getLoggedInUser();
      if (this.loggedInUser) {
        // this._initSocket(this.loggedInUserService.getTokenInUser());
      }
    });
  }

  public sendMessage(eventname, message) {
    this.socket.emit(eventname, message);
  }

  // HANDLER
  listen(eventname): Observable<any> {
    return Observable.create(observer => {

      this.socket?.on(eventname, (data) => {
        observer.next(data);
      });

      if (this.socket) {
        this.socket.on(eventname, (data) => {
          observer.next(data);
        });
      }

    });
  }

  // HANDLER
  listenSearch(eventname): Observable<any> {
    return Observable.create(observer => {
      this.socketSearch.on(eventname, (data) => {
        observer.next(data);
      });
    });
  }

  public _initSocket(token) {
    if (!this.socket) {
      this.socket = io(this.url, {
        query: {
          Authorization: token
        }
      }).connect();
    }

  }

  public initSearchSocket(UUID) {
    this.socketSearch = io(this.url, {
      query: {
        Authorization: 'uuid ' + UUID
      }
    }).connect();
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  public disconnectSearchSocket() {
    if (this.socketSearch) {
      this.socketSearch.disconnect();
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this.disconnect();
    this.disconnectSearchSocket();
  }

}
