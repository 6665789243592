import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

import { LoggedInUserService } from '../loggedInUser/logged-in-user.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  userUrl = environment.apiAuthenticationUrl + 'auth';
  forgotVerificationCodeUrl = environment.apiUrl + 'auth/forgot';
  recoveryUrl = environment.apiUrl + 'auth/change-password';
  pinUrl = environment.apiAuthenticationUrl + 'auth/validate';
  userSignUpUrl = environment.apiAuthenticationUrl + 'user/singUp';
  urlForgot = environment.apiUrl + 'auth/forgot';


  constructor(private httpClient: HttpClient,
    private translate: TranslateService,
    private loggedInUserService: LoggedInUserService) {
  }

  login(user: string, password: string) {
    const base64EncodedPw = btoa(user + ':' + password);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64EncodedPw
      }),
      username: user,
      password: password
    };

    return this.httpClient
      .get<any>(this.userUrl, httpOptions);

  }

  signUp(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient
      .post<any>(this.userSignUpUrl, data, httpOptions);
  }

  changePass(body): Observable<any> {
    return this.httpClient.post<any>(this.recoveryUrl, body);
  }


  onRecovery(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient
      .patch<any>(this.forgotVerificationCodeUrl, data, httpOptions);
  }

  passForgot(body): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('email', body.email);
    return this.httpClient.get<any>(this.urlForgot, { params: httpParams });
  }

  onValidatePin(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient
      .post<any>(this.pinUrl, data, httpOptions);
  }

  onForgotSendVerificationCode(data) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('username', data.toString());
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: httpParams
    };
    return this.httpClient
      .get<any>(this.forgotVerificationCodeUrl, httpOptions);
  }

  logout(): any {
    localStorage.removeItem('user');
    this.loggedInUserService.setLoggedInUser(null);
    localStorage.clear();
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn(): boolean {
    return this.getUser() !== null;
  }

  loginByAccount(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'language': this.translate.currentLang,
        'Authorization': token
      }),
      Authorization: token,
    };

    return this.httpClient
               .get<any>(this.userUrl, httpOptions);

  }

}

export const AUTH_PROVIDERS: Array<any> = [
  {
    provide: AuthenticationService,
    useClass: AuthenticationService
  }
];
