import {Environment} from '../app/core/interfaces/environments';
import packageInfo from "../../package.json";

export const environment: Environment = {
  production: false,
  defaultCurrency: "USD",
  logo_voucher: "assets/images/havanatur/logo-color.png",
  apiUrl: "https://apivuelos.havanatursa.com/api/",
  apiImageUrl: "https://cdnturismo.havanatursa.com/havanatur/charter/",
  apiAuthenticationUrl: "https://apivuelos.havanatursa.com/api/",
  clientAgencyMultiService: "https://gds.havanatursa.com",
  mainDomain: 'havanatursa.com',
  slogan: 'HAVANATUR EL ESPECIALISTA DE CUBA',
  agencyName: 'Havanatur',
  googleKey: "AIzaSyB1nfMaAZIgEmqYzTltM8sDOBbl50PuLHA",
  defaultLanguage: "es",
  measurementUnits: [
    {
      id: "squareMeters",
      name: {
        es: "Metros cuadrados",
        en: "Square meters",
      },
    },
    {
      id: "squareFeet",
      name: {
        es: "Pies cuadrados",
        en: "Square feet",
      },
    },
  ],
  smokingPolicyOptions: [
    {
      id: "smokingProhibited",
      name: {
        es: "Se prohibe fumar",
        en: "Smoking prohibited",
      },
    },
    {
      id: "smokingAllowed",
      name: {
        es: "Se permite fumar",
        en: "Smoking is allowed",
      },
    },
  ],
  address: 'Avenida 3ra, entre 72 y 74, Complejo Hotelero Neptuno Triton, Playa, La Habana, Cuba',
  phone: '+53 207-9753  y +53 204-1549',
  fax: '-',
  email: 'coordinaciones@havanatur.cu',
  versions: {
    app: packageInfo.version,
  },
};
