import { LoggedInUserService } from './core/loggedInUser/logged-in-user.service';

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsBackendUserGuard implements CanActivate {

  constructor(private loggedInUserService: LoggedInUserService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.loggedInUserService.getLoggedInUser() && this.loggedInUserService.getLoggedInUser().role !== 'client') {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
