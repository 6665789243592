import {EncryptDecryptService} from '../encrypt-decrypt/encrypt-decrypt.service';
import {Injectable} from '@angular/core';
import {IUser} from '../classes/user.class';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  $showCountDown = new Subject<any>();
  public $usePayEvent = new Subject<any>();
  public loggedInUserUpdated$: Subject<any> = new Subject<any>();
  loggedInUser: IUser;
  language: any;
  primaryColor: any;
  public reservationInfo: any;
  backendUsersService: any;

  constructor(private encryptDecryptService: EncryptDecryptService) {
    this.loggedInUser = this.getLoggedInUser();
  }

  public setNewAvatar(user) {
    this.loggedInUser.avatar = user.avatar;
    const dataString = JSON.stringify(user);
    const hashedPass = this.encryptDecryptService.encrypt(dataString);
    localStorage.setItem('U_Adm', hashedPass);
    localStorage.setItem('U_Adm', dataString);
    this.loggedInUserUpdated$.next(this.loggedInUser);
  }

  public getLoggedInUser(): any {
    let user = localStorage.getItem('U_Adm');
    if (!user) {
      return null;
    }
    try {
      user = this.encryptDecryptService.decrypt(user);
      const data = JSON.parse(user);
      return data;
    } catch (error) {
      localStorage.removeItem('U_Adm');
      return null;
    }
  }

  public getLocalStorageAgency() {
    return JSON.parse(localStorage.getItem('agency'));
  }

  public getTokenInUser() {
    return JSON.parse(localStorage.getItem('U_Adm')).token;
  }

  public setLoggedInUser(user: IUser) {
    this.loggedInUser = user;
    if (user) {
      const dataString = JSON.stringify(user);
      const hashedPass = this.encryptDecryptService.encrypt(dataString);
      localStorage.setItem('U_Adm', hashedPass);
    }
    // this.loggedInUserUpdated$.next(this.loggedInUser);
  }

  public setLanguage(language) {
    this.language = language;
  }

  public getLanguage() {
    return this.language;
  }

  public updateUserProfile(user) {
    this.loggedInUser.avatar = user.avatar;
    this.loggedInUser.image = user.image;
    this.loggedInUser.name = user.name;
    this.loggedInUser.lastName = user.lastName;
    this.loggedInUser.cellphone = user.cellphone;
    this.loggedInUser.username = user.username;
    this.loggedInUser.address = user.address;
    if (user?.ptaTax) {
      this.loggedInUser.ptaTax = user.ptaTax;
    }
    const dataString = JSON.stringify(this.loggedInUser);
    const hashedPass = this.encryptDecryptService.encrypt(dataString);
    localStorage.setItem('U_Adm', hashedPass);
    this.loggedInUserUpdated$.next(this.loggedInUser);
  }

  public logout() {
    this.loggedInUser = null;
    localStorage.removeItem('U_Adm');
    localStorage.removeItem('user');
    this.setLoggedInUser(null);
  }

  public isAdminUser() {
    let flag = false;
    const user = this.getLoggedInUser();
    if (!user) {
      return false;
    }
    user.roles.map(item => {
      if (item.type === 'Admin') {
        flag = true;
        return true;
      }
    });
    return flag;
  }

  public isClientUser() {
    let flag = false;
    const user = this.getLoggedInUser();
    if (!user) {
      flag = true;
      return false;
    }
    user.roles.map(item => {
      if (item.type === 'Client') {
        flag = true;
        return true;
      }
    });
    return flag;
  }

  public isMessengerUser() {
    let flag = false;
    const user = this.getLoggedInUser();
    if (!user) {
      return false;
    }
    user.roles.map(item => {
      if (item.type === 'Messenger') {
        flag = true;
        return true;
      }
    });
    return flag;
  }

  public isColaboratorUser() {
    let flag = false;
    const user = this.getLoggedInUser();
    if (!user) {
      flag = true;
      return false;
    }
    user.roles.map(item => {
      if (item.type === 'Collaborator') {
        return true;
      }
    });
    return flag;
  }

  isBackendUser() {
    const user = this.getLoggedInUser();
    let allPermissions = this.getBackendPermissions();
    let hasPermission = false;
    allPermissions.forEach(item => {
      if (user[item]) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  getBackendPermissions() {
    return [
      'canListAgencies',
      'canListContract',
      'canListHotels',
      'canListHotelChains',
      'canListBedTypes',
      'canListGeneralBedroomType',
      'canListTariffPlans',
      'canListCalendar',
      'canListBackendSearch',
      'canListBackendSearchNational',
      'canListBackendReservations',
      'canListUsers',
      'canListSettings',
    ];
  }

  public setReservationinfo(data) {
    this.reservationInfo = data;
  }

  public getReservationinfo() {
    return this.reservationInfo;
  }

  public setPrimaryColor(data) {
    this.primaryColor = data;
  }

  public getPrimaryColor() {
    return this.primaryColor;
  }

  public isPTA(): boolean {
    return this.loggedInUser.isPTA;
  }

}
