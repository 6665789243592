<div mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center" class="p-3">
    <span class="mb-2">
      {{'Nueva versión del sistema detectada. Se cargará nuevamente la página.'|translate}}
    </span>

    <!--    <span fxLayout="row" class="mb-2">-->
    <!--      {{'La próxima versión será superior a'|translate}}:&nbsp;-->
    <!--      <strong>v{{version}}</strong>-->
    <!--    </span>-->

    <button (click)="onClose()" mat-button color="primary">
      {{ "Aceptar" | translate | uppercase}}
    </button>
  </div>
</div>
